var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loadingHook > 0)?_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar-three-line"}}):(_vm.openEdit)?_c('AddAutoCard',{attrs:{"hook-detail":_vm.hookConfig},on:{"refetch-hooks":_vm.handleRefetch,"close":_vm.closeCard}}):(_vm.showHook)?_c('v-card',{staticClass:"text-h6",class:_vm.canEdit ? 'hover-card' : 'view-card',attrs:{"outlined":""},on:{"click":_vm.editHook}},[_c('v-card-text',{staticClass:"text-h6 font-weight-light"},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-start",attrs:{"cols":"11","lg":"11"}},[_c('v-icon',{staticClass:"pr-2",attrs:{"color":"accentPink"}},[_vm._v(" "+_vm._s(_vm.hookDetails[_vm.hook.event_type] ? _vm.hookDetails[_vm.hook.event_type].icon : '')+" ")]),_c('span',[_vm._v(" When "),(!_vm.hookName)?_c('span',[_vm._v("all ")]):_vm._e(),_vm._v(_vm._s(_vm.hookType)+" "),(_vm.flowName)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"font-weight-bold",domProps:{"innerHTML":_vm._s(_vm.hookName)}},on))]}}],null,false,2013373058)},[_vm._v(" "+_vm._s(_vm.flowNameList.join(', '))+" ")]):_c('span',{staticClass:"font-weight-bold",domProps:{"innerHTML":_vm._s(_vm.hookName)}}),(_vm.isAgent)?_c('span',[_vm._v(" with "+_vm._s(_vm.agentConfig && _vm.agentConfig.name ? _vm.agentConfig.name : 'this config'))]):_vm._e(),_vm._v(" "+_vm._s(_vm.hookDetail)),(_vm.includeSeconds)?_c('span',[_vm._v(" for "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.seconds)+" seconds")])]):_vm._e(),(_vm.includeTo)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({domProps:{"innerHTML":_vm._s(_vm.hookStates)}},on))]}}],null,false,2384394711)},[_c('span',[_vm._v(_vm._s(_vm.states.join(', ')))])]):_vm._e(),_vm._v(", then "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.hookAction))]),_vm._v(". ")],1),(_vm.isAgent)?_c('v-menu',{attrs:{"close-on-content-click":false,"open-on-hover":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("info")])],1)]}}],null,false,3181339119),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{attrs:{"width":"30vW"}},[_c('v-card-text',[_c('div',{staticClass:"pb-2"},[_vm._v(" To set up an automation for your agent, you need to add the agent-config-id flag at agent registration. ")]),_c('div',[_vm._v(" To use with a new agent, add this flag:")]),_c('div',{staticClass:"pb-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"cursor-pointer show-icon-hover-focus-only pa-2px",attrs:{"role":"button"},on:{"click":function($event){return _vm.copyToClipboard(("--agent-config-id " + _vm.agentConfigId))}}},on),[_vm._v(" --agent-config-id "+_vm._s(_vm.agentConfigId)+" ")])]}}],null,false,3738677316)},[_c('span',[_vm._v(" Click to copy ")])])],1),_c('div',[_vm._v(" Note that if you attach the agent-config-id to multiple agents, Prefect will only notify you if "),_c('span',{staticClass:"font-weight-bold"},[_vm._v("all")]),_vm._v(" agents are unhealthy.")])])],1)],1):_vm._e()],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"1","lg":"1"}},[(_vm.canEdit)?_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-2",attrs:{"text":"","small":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("more_horiz")])],1)]}}],null,false,187753343)},[_c('v-card',[_c('div',[_c('v-btn',{style:({ 'text-transform': 'none', 'min-width': '0px' }),attrs:{"text":"","loading":_vm.deletingHook,"width":"100%","color":"utilGrayDark"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteHook($event)}}},[_c('v-icon',{staticClass:"pr-4"},[_vm._v("delete")]),_vm._v(" Delete ")],1)],1)])],1):_vm._e()],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }